@import 'src/index.scss';

.App {
  min-height: 100vh;
  min-width: 100vw;
  background-color: $BrandCadet;
}

header{
  padding: $defaultPadding;
  display: flex;
  justify-content: space-between;
}

.logo{
  display: flex;
  justify-content: start;
  align-items: center;
}

.logo-link{
  text-decoration: none;
  display: inline-block;
}

.logo-icon{
  height: 70px;
  margin-right: 5px;
}

.logo-text{
  margin-left: 20px;
  font-weight: 400;
}

.container{
  margin: $defaultPadding;
  padding: 5%;
  border-radius: 10px;
}