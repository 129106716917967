@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap');

// colors
$BrandCadet: #27333B;

.brandCadet{
  color: $BrandCadet
}
.brandCadet-bg{
  background-color: $BrandCadet
}

$defaultPadding: 10px 20px;
.defaultPadding{
  padding: $defaultPadding;
}

// fonts
h1, h2, h3, h4, h5, h6, p, a, ul, li, span{
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
}

h1{
  color: #ffffff;
}

h2{
  color: #ffffff;
}

body {
  background-color: $BrandCadet;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  padding: 30px 20px 30px 20px;
}
