@import 'src/index.scss';

.home-page {
    text-align: center;
    h1{
        font-weight: 400;
    }
}

.badge-container {  
    text-align: center;

  .store-badge {
    margin: 10px;

    img {
      height: 75px;
    }
  }
}